<template>
  <div style="width: 100%; height: 100%">
    <div style="margin-left: 10px">
      <el-select
        v-model="cid"
        clearable
        v-if="showCompanySelect"
        placeholder="按代理商筛选"
        filterable
        @focus="companySelectFocus"
        style="margin-right: 10px"
        @change="changeCompany"
      >
        <el-option
          v-for="item in companyOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="userInfo common">
      <div class="userInfoItem" style="background: rgb(0, 181, 120)">
        今日新增用户数：{{ indexInfo.userNumDay }}
      </div>
      <div class="userInfoItem" style="background: rgb(255, 128, 139)">
        当月新增用户数：{{ indexInfo.userNumMonth }}
      </div>
      <div class="userInfoItem" style="background: rgb(252, 186, 82)">
        总用户数：{{ indexInfo.userNumCount }}
      </div>
    </div>
    <div class="orderInfo common">
      <div class="orderInfoItem" style="background: rgb(255, 168, 110)">
        今日订单量：{{ indexInfo.orderNumDay }}
      </div>
      <div class="orderInfoItem" style="background: rgb(255, 168, 110)">
        当月订单量：{{ indexInfo.orderNumMonth }}
      </div>
      <div class="orderInfoItem" style="background: rgb(255, 168, 110)">
        季度订单量：{{ indexInfo.orderNumQuarter }}
      </div>
      <div class="orderInfoItem" style="background: rgb(255, 168, 110)">
        年订单量：{{ indexInfo.orderNumYear }}
      </div>
      <div class="orderInfoItem" style="background: rgb(255, 168, 110)">
        总订单量：{{ indexInfo.orderNumCount }}
      </div>
    </div>
    <div class="moneyInfo common">
      <div class="moneyInfoItem" style="background: rgb(150, 152, 214)">
        今日交易额：{{ indexInfo.moneyNumDay / 100 }}
      </div>
      <div class="moneyInfoItem" style="background: rgb(150, 152, 214)">
        当月交易额：{{ indexInfo.moneyNumMonth / 100 }}
      </div>
      <div class="moneyInfoItem" style="background: rgb(150, 152, 214)">
        季度交易额：{{ indexInfo.moneyNumQuarter / 100 }}
      </div>
      <div class="moneyInfoItem" style="background: rgb(150, 152, 214)">
        年交易额：{{ indexInfo.moneyNumYear / 100 }}
      </div>
      <div class="moneyInfoItem" style="background: rgb(150, 152, 214)">
        总交易额：{{ indexInfo.moneyNumCount / 100 }}
      </div>
    </div>
    <div class="chart common">
      <div ref="orderChart" style="width: 50%; height: 500px"></div>
      <div ref="moneyChart" style="width: 50%; height: 500px"></div>
    </div>
  </div>
</template>

<script>
import { getIndexInfo, getCompanyList } from "../../api/api";
import router from "../../router";
import * as echarts from "echarts";
export default {
  name: "home",
  data() {
    return {
      showCompanySelect: false,
      cid: "",
      indexInfo: {
        userNumDay: 0,
        userNumMonth: 0,
        userNumCount: 0,
        orderNumDay: 0,
        orderNumMonth: 0,
        orderNumQuarter: 0,
        orderNumYear: 0,
        orderNumCount: 0,
        moneyNumDay: 0,
        moneyNumMonth: 0,
        moneyNumQuarter: 0,
        moneyNumYear: 0,
        moneyNumCount: 0,
        orderSeven: [],
        moneySeven: [],
      },
      date: [],
      companyOptions: [],
    };
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      router.push("/login");
      return;
    }
    if (userInfo.type == 0) {
      // 显示代理商选择框
      this.showCompanySelect = true;
    } else {
      // 限定cid
      this.cid = userInfo.cid;
    }

    for (var i = 6; i >= 0; i--) {
      var date = new Date();
      date.setDate(date.getDate() - i);
      this.date.push(date.getMonth() + 1 + "/" + date.getDate());
    }

    this.getIndexData();
  },
  methods: {
    // 代理商选择框获得焦点事件
    companySelectFocus() {
      if (this.companyOptions.length > 0) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          if (resultList.length > 0) {
            this.companyOptions = [];
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 选择代理商的事件
    changeCompany(value) {
      this.cid = value;
      this.getIndexData();
    },

    // 获取首页数据
    getIndexData() {
      getIndexInfo({ cid: this.cid }).then((data) => {
        if (data && data.code === 0) {
          this.indexInfo = data.data;

          for (let i = 0; i < this.indexInfo.moneySeven.length; i++) {
            this.indexInfo.moneySeven[i] = this.indexInfo.moneySeven[i] / 100;
          }

          // 初始化图表
          var orderChart = echarts.init(this.$refs.orderChart);
          orderChart.setOption({
            title: {
              text: "近七天订单量",
            },
            tooltip: {},
            xAxis: {
              data: this.date,
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "line",
                color: ["#00aa99"],
                data: this.indexInfo.orderSeven,
              },
            ],
          });

          var moneyChart = echarts.init(this.$refs.moneyChart);
          moneyChart.setOption({
            title: {
              text: "近七天交易额",
            },
            tooltip: {},
            xAxis: {
              data: this.date,
            },
            yAxis: {},
            series: [
              {
                name: "销量",
                type: "bar",
                color: new echarts.graphic.LinearGradient(
                  0,
                  1,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#1268f3", // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: "#08a4fa", // 60% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#01ccfe", // 100% 处的颜色
                    },
                  ],
                  false
                ),
                data: this.indexInfo.moneySeven,
              },
            ],
          });
        } else {
          router.push("/login");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.common {
  display: flex;
}
.userInfo {
  margin-top: 20px;
  font-size: 20px;
  height: 80px;
  color: white;
}
.userInfoItem {
  margin: 5px;
  width: 33%;
  height: 70px;
  border-radius: 30px;
  text-align: center;
  line-height: 70px;
}
.orderInfo {
  margin-top: 15px;
  height: 60px;
  color: white;
}
.orderInfoItem {
  margin: 5px;
  width: 24%;
  height: 50px;
  border-radius: 30px;
  text-align: center;
  line-height: 50px;
}
.moneyInfo {
  height: 60px;
  color: white;
}
.moneyInfoItem {
  margin: 5px;
  width: 24%;
  height: 50px;
  border-radius: 30px;
  text-align: center;
  line-height: 50px;
}
.chart {
  margin-left: 30px;
  margin-top: 50px;
}
</style>
